import { useEffect } from "react";

function NotFound() {
    useEffect(() => {
        document.title = "404 Not Found"
    });
    return (
        <>
            <div className="max-w-7xl mx-auto py-12 px-4 text-center">
                <h2 className="text-4xl font-extrabold tracking-tight text-gray-900">
                    <span className="block text-8xl">404</span>
                    <span className="block text-indigo-600">Not Found.</span>
                </h2>
            </div>
        </>
    )
}

export default NotFound;