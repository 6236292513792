import { useEffect } from "react";
import Modal from "../components/Modal";
import { Link } from "react-router-dom";

function Main() {
  useEffect(() => {
    document.title = "Main"
  });
  return (
    <>
      <div className="flex flex-col h-screen">
        <div className="flex justify-between mt-1">
          <span className="font-medium text-indigo-600">&nbsp;Progres<span className="animate-pulse transform-gpu">...</span></span>
          <span className="font-medium text-indigo-600" id="persen">0%&nbsp;</span>
        </div>
        <div className="bg-indigo-100 rounded h-2.5 mt-1">
          <div className="bg-indigo-600 h-2.5 rounded w-[0%] animate-bounce transform-gpu" id="progres"></div>
        </div>
        <div className="m-auto">
          <Link to={"/"} className="text-6xl font-extrabold text-indigo-600 animate-pulse transform-gpu">Math Magic</Link>
        </div>
      </div>
      <Modal />
    </>
  )
}

export default Main;