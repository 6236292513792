import { useEffect } from "react";

function About() {
  useEffect(() => {
    document.title = "Tentang"
  });
  return (
    <>
      <main className="container max-w-3xl m-auto">
        <h1 className="text-center font-bold text-4xl mt-7">Tentang</h1>
        <p></p>
      </main>
    </>
  )
}

export default About;