var a = [], 
    b = [], 
    c = [], 
    d = [], 
    e = [],
    f = [],
    g = [],
    h = [];

h.push({a},{b},{c},{d},{e},{f},{g});

var ab = Math.pow(2, 0), 
    bb = Math.pow(2, 1), 
    cb = Math.pow(2, 2), 
    db = Math.pow(2, 3), 
    eb = Math.pow(2, 4),
    fb = Math.pow(2, 5),
    gb = Math.pow(2, 6);

async function bg(bd, be, bf, bg) {
    for (let i = be * bd; i <= bg; i++) bf[i] += be;
}

async function ae() {
    for (let i = ab; i <= 50; i++) a.push(i);
    for (let i = ab; i <= 49; i++) a[i] += a[i] - 1;
    
    for (let i = bb; i <= 51; i++) b.push(i);
    for (let i = bb; i <= 49; i++) b[i] += bb;
    for (let i = 2; i <= 24; i++) bg(i, bb, b, 49);
    
    for (let i = cb; i <= 52; i++) c.push(i);
    for (let i = cb; i <= 48; i++) c[i] += cb;
    for (let i = 2; i <= 24; i++) bg(i, cb, c, 48);
    
    for (let i = db; i <= 55; i++) d.push(i);
    for (let i = db; i <= 47; i++) d[i] += db;
    for (let i = 2; i <= 24; i++) bg(i, db, d, 47);
    
    for (let i = eb; i <= 63; i++) e.push(i);
    for (let i = eb; i <= 47; i++) e[i] += eb;
    for (let i = 2; i <= 24; i++) bg(i, eb, e, 47);
    
    for (let i = fb; i <= 68; i++) f.push(i);
    for (let i = fb; i <= 36; i++) f[i] += fb;
    for (let i = 2; i <= 18; i++) bg(i, fb, f, 36);
    
    for (let i = gb; i <= 100; i++) g.push(i);
    for (let i = gb; i <= 36; i++) g[i] += gb;
    for (let i = 2; i <= 18; i++) bg(i, gb, g, 36);
}

ae()

function Numagic() {
    return h;
}

export default Numagic;