/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { LightBulbIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline'
import Numagic from '../lib/Numagic';
import { Link } from 'react-router-dom';

function BaseModal(props) {
  return (
    <Transition.Root show={props.modal} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={props.focus} onClose={props.setModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      <QuestionMarkCircleIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Angka-mu Ada Di Daftar Berikut Ini?
                      </Dialog.Title>
                      <div className="mt-2">
                        {props.children}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-indigo-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={props.faModal}
                  >
                    Ada
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={props.ftModal}
                    ref={props.focus}
                  >
                    Tidak
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function FinalModal(props) {
  return (
    <Transition.Root show={props.modal} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={props.focus} onClose={props.setModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      <LightBulbIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-5">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {props.message}
                      </Dialog.Title>
                      <div className="mt-2">
                        {props.children}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-indigo-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={props.faModal}
                  >
                    Coba Lagi
                  </button>
                  <Link
                    to={"/tentang"}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={props.ftModal}
                    ref={props.focus}
                  >
                    Tentang
                  </Link>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function TableTr(props) {
  return (
    <tr>
      {props.obj.map((n) =>
        <td className="px-1 py-1 border" key={n}>{n}</td>
      )}
    </tr>
  )
}

export default function Modal() {
  const [angka, setAngka] = useState(0)
  const [modal1, setModal1] = useState(true)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [modal4, setModal4] = useState(false)
  const [modal5, setModal5] = useState(false)
  const [modal6, setModal6] = useState(false)
  const [modal7, setModal7] = useState(false)
  const [modalf, setModalf] = useState(false)
  const cancelButtonRef = useRef(null)
  const Number = Numagic()

  async function faModal1() {
    setAngka(r => r + Number[0].a[0]);
    setModal1(false);
    setModal2(true);
    document.getElementById("persen").innerHTML = "15%&nbsp;";
    document.getElementById("progres").classList.remove("w-[0%]");
    document.getElementById("progres").classList.add("w-[15%]");
  }
  async function ftModal1() {
    setModal1(false);
    setModal2(true);
    document.getElementById("persen").innerHTML = "15%&nbsp;"
    document.getElementById("progres").classList.remove("w-[0%]");
    document.getElementById("progres").classList.add("w-[15%]");
  }

  async function faModal2() {
    setAngka(r => r + Number[1].b[0]);
    setModal2(false);
    setModal3(true);
    document.getElementById("persen").innerHTML = "30%&nbsp;"
    document.getElementById("progres").classList.remove("w-[15%]");
    document.getElementById("progres").classList.add("w-[30%]");
  }
  async function ftModal2() {
    setModal2(false);
    setModal3(true);
    document.getElementById("persen").innerHTML = "30%&nbsp;"
    document.getElementById("progres").classList.remove("w-[15%]");
    document.getElementById("progres").classList.add("w-[30%]");
  }

  async function faModal3() {
    setAngka(r => r + Number[2].c[0]);
    setModal3(false);
    setModal4(true);
    document.getElementById("persen").innerHTML = "45%&nbsp;"
    document.getElementById("progres").classList.remove("w-[30%]");
    document.getElementById("progres").classList.add("w-[45%]");
  }
  async function ftModal3() {
    setModal3(false);
    setModal4(true);
    document.getElementById("persen").innerHTML = "45%&nbsp;"
    document.getElementById("progres").classList.remove("w-[30%]");
    document.getElementById("progres").classList.add("w-[45%]");
  }

  async function faModal4() {
    setAngka(r => r + Number[3].d[0]);
    setModal4(false);
    setModal5(true);
    document.getElementById("persen").innerHTML = "60%&nbsp;"
    document.getElementById("progres").classList.remove("w-[45%]");
    document.getElementById("progres").classList.add("w-[60%]");
  }
  async function ftModal4() {
    setModal4(false);
    setModal5(true);
    document.getElementById("persen").innerHTML = "60%&nbsp;"
    document.getElementById("progres").classList.remove("w-[45%]");
    document.getElementById("progres").classList.add("w-[60%]");
  }

  async function faModal5() {
    setAngka(r => r + Number[4].e[0]);
    setModal5(false);
    setModal6(true);
    document.getElementById("persen").innerHTML = "75%&nbsp;"
    document.getElementById("progres").classList.remove("w-[60%]");
    document.getElementById("progres").classList.add("w-[75%]");
  }
  async function ftModal5() {
    setModal5(false);
    setModal6(true);
    document.getElementById("persen").innerHTML = "75%&nbsp;"
    document.getElementById("progres").classList.remove("w-[60%]");
    document.getElementById("progres").classList.add("w-[75%]");
  }

  async function faModal6() {
    setAngka(r => r + Number[5].f[0]);
    setModal6(false);
    setModal7(true);
    document.getElementById("persen").innerHTML = "90%&nbsp;"
    document.getElementById("progres").classList.remove("w-[75%]");
    document.getElementById("progres").classList.add("w-[90%]");
  }
  async function ftModal6() {
    setModal6(false);
    setModal7(true);
    document.getElementById("persen").innerHTML = "90%&nbsp;"
    document.getElementById("progres").classList.remove("w-[75%]");
    document.getElementById("progres").classList.add("w-[90%]");
  }

  async function faModal7() {
    setAngka(r => r + Number[6].g[0]);
    setModal7(false);
    setModalf(true);
    document.getElementById("persen").innerHTML = "100%&nbsp;"
    document.getElementById("progres").classList.remove("w-[90%]");
    document.getElementById("progres").classList.add("w-[100%]");
  }
  async function ftModal7() {
    setModal7(false);
    setModalf(true);
    document.getElementById("persen").innerHTML = "100%&nbsp;"
    document.getElementById("progres").classList.remove("w-[90%]");
    document.getElementById("progres").classList.add("w-[100%]");
  }

  async function fModal() {
    setAngka(0);
    setModalf(false);
    setModal1(true);
    document.getElementById("persen").innerHTML = "0%&nbsp;"
    document.getElementById("progres").classList.remove("w-[100%]");
    document.getElementById("progres").classList.add("w-[0%]");
  }

  return (
    <>
      <BaseModal modal={modal1} setModal={setModal1} focus={cancelButtonRef} faModal={faModal1} ftModal={ftModal1}>
        <table className='text-xl min-w-full m-auto'>
          <tbody className=''>
            <TableTr obj={Number[0].a.slice(0, 10)} />
            <TableTr obj={Number[0].a.slice(10, 20)} />
            <TableTr obj={Number[0].a.slice(20, 30)} />
            <TableTr obj={Number[0].a.slice(30, 40)} />
            <TableTr obj={Number[0].a.slice(40, 50)} />
          </tbody>
        </table>
      </BaseModal>
      <BaseModal modal={modal2} setModal={setModal2} focus={cancelButtonRef} faModal={faModal2} ftModal={ftModal2}>
        <table className='text-xl min-w-full m-auto'>
          <tbody className=''>
            <TableTr obj={Number[1].b.slice(0, 10)} />
            <TableTr obj={Number[1].b.slice(10, 20)} />
            <TableTr obj={Number[1].b.slice(20, 30)} />
            <TableTr obj={Number[1].b.slice(30, 40)} />
            <TableTr obj={Number[1].b.slice(40, 50)} />
          </tbody>
        </table>
      </BaseModal>
      <BaseModal modal={modal3} setModal={setModal3} focus={cancelButtonRef} faModal={faModal3} ftModal={ftModal3}>
        <table className='text-xl min-w-full m-auto'>
          <tbody className=''>
            <TableTr obj={Number[2].c.slice(0, 10)} />
            <TableTr obj={Number[2].c.slice(10, 20)} />
            <TableTr obj={Number[2].c.slice(20, 30)} />
            <TableTr obj={Number[2].c.slice(30, 40)} />
            <TableTr obj={Number[2].c.slice(40, 50)} />
          </tbody>
        </table>
      </BaseModal>
      <BaseModal modal={modal4} setModal={setModal4} focus={cancelButtonRef} faModal={faModal4} ftModal={ftModal4}>
        <table className='text-xl min-w-full m-auto'>
          <tbody className=''>
            <TableTr obj={Number[3].d.slice(0, 10)} />
            <TableTr obj={Number[3].d.slice(10, 20)} />
            <TableTr obj={Number[3].d.slice(20, 30)} />
            <TableTr obj={Number[3].d.slice(30, 40)} />
            <TableTr obj={Number[3].d.slice(40, 50)} />
          </tbody>
        </table>
      </BaseModal>
      <BaseModal modal={modal5} setModal={setModal5} focus={cancelButtonRef} faModal={faModal5} ftModal={ftModal5}>
        <table className='text-xl min-w-full m-auto'>
          <tbody className=''>
            <TableTr obj={Number[4].e.slice(0, 10)} />
            <TableTr obj={Number[4].e.slice(10, 20)} />
            <TableTr obj={Number[4].e.slice(20, 30)} />
            <TableTr obj={Number[4].e.slice(30, 40)} />
            <TableTr obj={Number[4].e.slice(40, 50)} />
          </tbody>
        </table>
      </BaseModal>
      <BaseModal modal={modal6} setModal={setModal6} focus={cancelButtonRef} faModal={faModal6} ftModal={ftModal6}>
        <table className='text-xl min-w-full m-auto'>
          <tbody className=''>
            <TableTr obj={Number[5].f.slice(0, 10)} />
            <TableTr obj={Number[5].f.slice(10, 20)} />
            <TableTr obj={Number[5].f.slice(20, 30)} />
            <TableTr obj={Number[5].f.slice(30, 40)} />
          </tbody>
        </table>
      </BaseModal>
      <BaseModal modal={modal7} setModal={setModal7} focus={cancelButtonRef} faModal={faModal7} ftModal={ftModal7}>
        <table className='text-xl min-w-full m-auto'>
          <tbody className=''>
            <TableTr obj={Number[6].g.slice(0, 10)} />
            <TableTr obj={Number[6].g.slice(10, 20)} />
            <TableTr obj={Number[6].g.slice(20, 30)} />
            <TableTr obj={Number[6].g.slice(30, 40)} />
            <TableTr obj={Number[6].g.slice(40, 50)} />
          </tbody>
        </table>
      </BaseModal>
      <FinalModal modal={modalf} setModal={setModalf} focus={cancelButtonRef} faModal={fModal} message={angka === 0 ? "Saya Tidak Tahu ¯\\_(ツ)_/¯" : angka > 100 ? "Jangan Begitu Ya Lain Kali ( ´･･)ﾉ(._.`)" : "Angka Kamu Adalah ヾ(⌐■_■)ノ♪"}>
        {angka === 0 ? (
          <>
            <p>Pastikan kamu memilih salah satu angka dari <b>1 sampai 100</b>, misal: 17</p>
            <p>Atau jika kamu sudah memilih angka dengan benar, cobalah lagi dan pastikan kamu menekan tombol yang tepat, misal: jika angka yang kamu pilih ada di daftar angka, tekan tombol <b>Ada</b>, dan juga sebaliknya jika tidak ada maka tekan tombol <b>Tidak</b></p>
          </>
        ) : angka > 100 ? (
          <>
            <p>WaterPark Mann..</p>
          </>
        ) : (
          <>
            <p className='text-9xl'>{angka}</p>
            <br/>
            <p>Kalau mau tahu cara kerjanya,<br/>klik tombol <b>Tentang</b> ya ^_____^</p>
          </>
        )}
      </FinalModal>
    </>
  )
}
